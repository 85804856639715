import * as React from "react";

const NoPage = () => {
    return (
        <React.Fragment>
            <h1>404</h1>
        </React.Fragment>
    );
};

export default NoPage;