import {Example} from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "电影满江红怎么样？",
        value: "电影满江红怎么样？"
    },
    {
        text: "进口医疗器械不贴中文标签会受到什么处罚？",
        value: "进口医疗器械不贴中文标签会受到什么处罚？"
    },
    {
        text: "盖亚的复仇中，单刷BOSS推荐哪个职业?",
        value: "盖亚的复仇中，单刷BOSS推荐哪个职业?"
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({onExampleClicked}: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked}/>
                </li>
            ))}
        </ul>
    );
};
